<template>
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.9835 2.90122C17.0422 3.04749 14.1839 3.93403 11.6692 5.48003C9.15456 7.02602 7.06346 9.1823 5.58654 11.7523C4.10962 14.3223 3.29385 17.2244 3.21359 20.1939C3.13332 23.1634 3.79112 26.106 5.12702 28.7533L3.25455 37.9218C3.23125 38.0357 3.23679 38.1538 3.27066 38.265C3.30453 38.3762 3.36564 38.477 3.44834 38.5581C3.53104 38.6392 3.63267 38.698 3.74384 38.729C3.855 38.76 3.97213 38.7623 4.08439 38.7356L12.9921 36.6064C15.4061 37.8174 18.0631 38.4541 20.759 38.4677C23.455 38.4812 26.1181 37.8713 28.5439 36.6847C30.9697 35.4981 33.0936 33.7664 34.7525 31.6226C36.4113 29.4788 37.561 26.98 38.1131 24.3182C38.6653 21.6563 38.6052 18.9022 37.9376 16.2674C37.27 13.6325 36.0125 11.1872 34.2618 9.11903C32.5111 7.0509 30.3138 5.41508 27.8386 4.3372C25.3634 3.25933 22.6763 2.76809 19.9835 2.90122Z" :stroke="props.color" stroke-width="2.4434" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M29.4086 24.5315L25.9914 23.5419C25.7709 23.4782 25.5374 23.4757 25.3156 23.5347C25.0938 23.5938 24.8919 23.7121 24.7312 23.8773L23.8955 24.7359C23.7234 24.9136 23.5031 25.0358 23.2622 25.0871C23.0213 25.1384 22.7708 25.1165 22.5422 25.0241C20.9258 24.3664 17.5259 21.3171 16.6581 19.79C16.5362 19.5742 16.4804 19.3267 16.4979 19.0789C16.5155 18.8312 16.6054 18.5942 16.7565 18.398L17.4856 17.4461C17.6259 17.263 17.7146 17.0451 17.7422 16.8153C17.7699 16.5856 17.7355 16.3525 17.6426 16.1409L16.2058 12.8613C16.1257 12.6785 16.0044 12.5171 15.8515 12.39C15.6987 12.2628 15.5185 12.1733 15.3254 12.1287C15.1323 12.084 14.9315 12.0854 14.739 12.1328C14.5465 12.1802 14.3676 12.2722 14.2166 12.4015C13.2629 13.2153 12.1319 14.4512 11.9944 15.821C11.752 18.236 12.7786 21.28 16.6605 24.9345C21.1451 29.1575 24.7372 29.7178 27.0755 29.1438C28.4021 28.8197 29.4619 27.521 30.1305 26.4573C30.237 26.2888 30.303 26.0975 30.3233 25.8987C30.3437 25.6998 30.3178 25.499 30.2477 25.312C30.1776 25.1251 30.0653 24.9572 29.9196 24.8217C29.7739 24.6862 29.599 24.5868 29.4086 24.5315Z" :fill="props.color"/>
    </svg>
</template>
<script setup>
    const props = defineProps({
        color: {
            type: String,
            default: '#10B981'
        }
    })
</script>